@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA55ROMAN.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: 400;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA56ITALIC.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA45LIGHT.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: 300;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA46LIGHTITALIC.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA75BOLD.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: 700;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA76BOLDITALIC.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 900;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA95BLACK.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica";
  font-style: italic;
  font-weight: 900;
  src: local("Helvetica"),
    url("https://fonts.cdnfonts.com/s/38060/HELVETICA96BLACKITALIC.woff")
      format("woff");
}
