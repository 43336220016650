@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 700;
  src: local("Helvetica Neue"),
    url("https://fonts.cdnfonts.com/s/29136/HelveticaNeueBold.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: 700;
  src: local("Helvetica Neue"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica 76 Bold Italic.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: 900;
  src: local("Helvetica Neue"),
    url("https://fonts.cdnfonts.com/s/29136/HelveticaNeueBlackItalic 1.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: italic;
  font-weight: 500;
  src: local("Helvetica Neue"),
    url("https://fonts.cdnfonts.com/s/29136/HelveticaNeueMediumItalic.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 900;
  src: local("Helvetica Neue"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica95Black.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 25 UltraLight";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica 25 UltraLight"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica25UltraLight_22433.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 35 Thin";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica 35 Thin"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica35Thin_22435.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 45 Light";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica 45 Light"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica45Light_22437.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 55 Roman";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica 55 Roman"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica55Roman_22439.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 65 Medium";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica 65 Medium"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica65Medium_22443.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 25 UltraLight";
  font-style: italic;
  font-weight: 400;
  src: local("Helvetica 25 UltraLight"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica26UltraLightItalic_22434.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 35 Thin";
  font-style: italic;
  font-weight: 400;
  src: local("Helvetica 35 Thin"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica36ThinItalic_22436.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 45 Light";
  font-style: italic;
  font-weight: 400;
  src: local("Helvetica 45 Light"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica46LightItalic_22438.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 55 Roman";
  font-style: italic;
  font-weight: 400;
  src: local("Helvetica 55 Roman"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica56Italic_22440.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 65 Medium";
  font-style: normal;
  font-weight: 700;
  src: local("Helvetica 65 Medium"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica85Heavy_22449.woff")
      format("woff");
}
@font-face {
  font-family: "Helvetica 65 Medium";
  font-style: italic;
  font-weight: 700;
  src: local("Helvetica 65 Medium"),
    url("https://fonts.cdnfonts.com/s/29136/Helvetica86HeavyItalic_22450.woff")
      format("woff");
}
