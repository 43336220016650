.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #52b540;
  width: 100px;
  height: 100px;
  margin: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderbackground {
  background: rgba(255, 255, 255, 0.63);
  opacity: 0.7;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loaderGif {
  width: 100px;
  height: 100px;
  margin: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}



/* .loader-container{
    background: rgba(255, 255, 255, 0.6);
    position: fixed;
    height:100vh;
    width:100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width:100px;
    height:100px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 8px;
    border: 8px solid #48b37d5c;
    border-radius: 50%;
    animation: lds-ring .8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #48b37d5c transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */