.steps {
  @apply w-11/12 items-end m-auto uppercase flex mt-16 mb-8 font-semibold justify-evenly text-center;
}
.stespheading {
  color: #339495;
  @apply text-xl font-bold;
}
.stepsGray {
  @apply text-secondary text-xl font-normal;
}
.plainIcon {
  @apply w-11/12 m-auto uppercase flex mt-8 mb-8 items-center font-semibold justify-evenly text-center;
}
@media only screen and (max-width: 600px) {
  .steps {
    flex-direction: column;
    align-items: unset;
    margin: 20px auto;
  }
  .stespheading{
     line-height: 14px;
  }
  .stepsGray{
    line-height: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) { 
  .steps {
      align-items: flex-end;
      display: flex;
      justify-content: space-evenly;
      margin: 30px 30px;
      text-align: center;
      font-size: x-small;
   }
   .stepsGray{
      font-size: 17px;
    }
    .stespheading {
      font-size: 17px;
    }
}