body {
  background-color: rgba(128, 128, 128, 0.082);
}
.BgSection {
  background-image: url("../../assests/images/destination.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  @apply pb-24;
}
.cityTitle {
  font-size: 60px;
  @apply text-center text-white font-semibold;
}
.deslocation {
  @apply flex mt-8 mb-8 text-white font-semibold justify-evenly md:w-1/2 text-center m-auto  sm:w-full;
}

@media only screen and (max-width: 600px) {
  .deslocation {
    flex-direction: column;
  }
  .cityTitle {
    font-size: 48px;
  }
  .BgSection {
    height: 520px;
  }

}
