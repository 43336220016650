@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./adobe.css");
@import url("./helvetica-neue.css");
/* @import url("http://fonts.cdnfonts.com/css/helvetica-neue-9"); */
body h1,
h2,
h3,
p,
a,
button,
input,
optgroup,
select,
option,
label,
textarea {
	font-family: "futura-pt", sans-serif;
}
.HelveticaFont {
	font-family: "Helvetica", sans-serif;
}

.hero-section-bg {
	background-image: url("./assests/images/HeroSection.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider-wrapper {
	max-width: 1100px;
	width: 100%;
	margin: auto;
}
.postTitleSlide .slider.title {
	padding-top: 30px;
	background-color: #fff;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.slick-slide {
	padding: 13px;
}

div.slick-slide > div > div.slick-slide {
	padding: 0;
}
.slick-slide.postWarrap {
	margin: 0px 0px 25px 0px;
}
.slick-slide .slick-slide {
	text-align: center;
	position: relative;
	background-color: #fff;
	border-radius: 8px;
}
.slick-slide:focus {
	outline: none;
}
.slick-slide-title {
	text-transform: uppercase;
	text-align: left;
}
.slick-slide.slick-active {
	background: #e5e7eb;
}
.slider.description {
	padding-bottom: 10px;
	text-align: left;
	font-size: 13px;
	color: #a6adb4;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 0px;
	background-color: #fff;
	border-radius: 10px;
}
.activtiesBoxes {
	width: -webkit-fill-available;
}
.fit-webkit-width {
	width: -webkit-fill-available;
}
.slider.title {
	padding-top: 40px;
	background-color: #fff;
	padding-left: 40px;
	padding-right: 40px;
}
.slider-time-date {
	padding-bottom: 44px;
	padding-left: 27px;
	padding-right: 27px;
}
.slick-slide-image {
	width: 100%;
	max-width: 100%;
	height: 175px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	object-fit: unset;
}
.slick-slide-label {
	text-align: left;
	bottom: 0px;
	width: 100%;
}
.slick-next:before {
	content: url("./assests//images//arrow.png") !important;
	background: 0 0 !important;
}
.slick-prev:before {
	content: url("./assests//images/arrow\ back.png") !important;
	background: 0 0 !important;
}
/* .slick-prev:before,
.slick-next:before {
  color: #777777 !important;
} */
.postArea .post-feature-image {
	margin-bottom: 30px;
}
.slider-wrapper button.slick-arrow.slick-next {
	right: 10px;
	top: -12px;
}
.slider-wrapper button.slick-arrow.slick-prev {
	left: unset;
	right: 42px;
	top: -12px;
}
.borderColor {
	border-color: #339495;
}
.popupDescri {
	color: #339495;
}
.timeLeft .timeIcons {
	display: unset;
}
.formsetPro {
	margin: 0px 0px !important;
}
.termsCond {
	color: #343638;
	font-size: 17px;
	line-height: 26px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.setModalBg {
	background: #000000a6;
}
.postShortDesc {
	padding-right: 16px;
}

.custom-select select:required:invalid {
	color: #a5afa7;
	font-size: 15px;
}
option[value=""][disabled] {
	display: none;
}
option {
	color: #202124;
	font-weight: 700;
	font-size: 14px;
}
.postTitleSlide {
	padding-left: 0px !important;
	padding-top: 15px !important;
}
.postDateTime {
	padding-left: 0px !important;
}
.didYouknow {
	font-size: 45px;
}
.GrayBgButton {
	background-color: #e0e0e0;
}
.GreenBgButton {
	background-color: #27b271;
}
.textSize {
	font-size: 40px;
}
.selLocTitle {
	font-size: 21px;
}
.textReportTitle {
	font-size: 50px;
}
.textSubTitle {
	font-size: 25px;
}
.exportbtn {
	font-size: 14px;
}
.adbColor {
	color: #343638;
}
.adbothColor {
	color: #3e3e3e;
}
.otherAdobClr {
	color: #cbd0d3;
}
.postDateRange {
	font-size: 13px;
}
.fontSizeUp {
	font-size: 13px;
}
img.postFeature {
	width: 720px;
	height: 400px;
}
.postFeatureImageWrapp .slick-slide-image {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 720px;
	height: 226px;
}
.slick-slide.postWarrap {
	background-color: unset;
}
.postWarrap .slider.title {
	background-color: unset;
}
.iunderstand {
	font-size: 20px;
	line-height: 38px;
}
.no-suggestions {
	color: #999;
	padding: 0.5rem;
}
.suggestions {
	border: 1px solid #999;
	border-top-width: 0;
	list-style: none;
	max-height: 157px;
	overflow-y: auto;
	padding-left: 0;
	/* width: calc(100% - 11.2rem); */
	background: #fff;
	position: absolute;
	z-index: 9;
	border: none;
}
.suggestions::-webkit-scrollbar {
	display: none;
}
.suggestions li {
	padding: 0.5rem;
}
.suggestion-active,
.suggestions li:hover {
	background-color: #e0f4eb;
	color: #202124;
	cursor: pointer;
	font-weight: 700;
}
/* .suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
} */
.checkarea {
	top: 19px;
}

.postShortDesc {
	line-height: 26px;
	top: 19rem;
}
.postShortDesc2 {
	line-height: 15px;
	top: 10rem;
}
.scrollBar {
	overflow: scroll;
	/* overflow-x:hidden */
}

.scrollBar::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.scrollBar::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	/* background: rgba(255, 255, 255, 0.3);  */
	background-color: #ccc;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

.max-width-fit{
	max-width: 86rem;
}

.scrollBar::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 255, 0.3);
}

.vaccineDetails {
	margin-top: 82px;
}

.Activity_title h2 {
	font-weight: bold;
	font-size: 20px;
	padding-right: 36px;
}

.activity_heading h1 {
	font-size: 30px;
	font-weight: normal;
}
.trip-summery_heading {
	font-size: 30px;
	font-weight: normal;
}
.summery_title {
	font-size: 20px;
	line-height: unset;
}
.trip_image {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.model_heading {
	font-weight: 400;
	font-size: 30px;
	line-height: 40px;
	font-family: "futura-pt";
}
.grclose {
	visibility: hidden;
}
.hoverforclose:hover .grclose {
	visibility: visible;
}
.footer-bg {
	background: #333333;
}
.add-more-btn-mobile {
	display: none;
}

.add-btn-deskto-view {
	display: block;
}

.text-white-global {
	color: #fff !important;
}
.textDecoration-safari {
	border-color: #fff !important;
}

.report-term-mobile {
	display: none;
}

.slider.title.postTitleSlide {
	padding-right: 0px;
}

/* Responsive section */
@media (max-width: 767px) {
	.logoResize {
		width: 150px;
	}
	.report-term-mobile {
		display: block;
	}
	.report-term-desk {
		display: none;
	}
}

@media (max-width: 1024px) and (min-width: 768px) {
	.viewReportSec {
		width: 100% !important;
		padding: 1px 15px !important;
	}
}

@media (max-width: 600px) {
	.firstlocation input#inline-full-name {
		width: 100%;
		height: 47px;
	}
	img.conuntryImage {
		height: inherit;
	}
	.checkarea {
		padding-right: 0px;
	}
	.chooseCountry {
		width: 100%;
		padding: 0px 25px;
	}
	.vertPost .slick-slider.slick-initialized {
		margin-top: 50px;
	}
	.postArea {
		padding: 0px 12px;
	}
	.slider-wrapper-post h1 {
		text-align: center;
	}
	.otherHeadline {
		text-align: center;
	}
	.textSize {
		font-size: 25px;
	}
	.exportbtn {
		font-size: 20px;
	}
	.didYouknow {
		font-size: 33px;
	}
	.posttitlepost {
		bottom: 10rem;
	}
	.postShortDesc {
		line-height: 16px;
	}
	.timecount {
		bottom: 1rem;
	}
	.chooseCountry img {
		right: 2rem;
	}
	.suggestions {
		width: 100%;
	}
	.vaccineDetails {
		margin-top: 0px;
	}
}

@media (max-width: 480px) and (min-width: 320px) {
	p.postShortDesc {
		bottom: unset;
		top: 8rem;
	}
	.postblogstate {
		top: 19px;
	}
	.posttitlepost {
		bottom: unset;
		top: 3rem;
	}
}

@media screen and (max-width: 768px) {
	.multiSteps_steps__Wq5vQ {
		font-size: 0.3rem !important;
	}
	.btn-group {
		flex-direction: column;
	}
	.export-btn {
		margin-left: 0rem;
		margin-right: 0rem;
		margin: 14px 0px;
	}
	.country-search-input {
		justify-content: center;
	}
	.add-btn-deskto-view {
		display: none;
	}
	.add-more-btn-mobile {
		display: block;
	}
	button.clear-btn {
		right: 5px;
	}
	input.searchCountryInput {
		width: 100%;
	}
	.firstlocation {
		width: 100%;
	}
	.Activity_title h2 {
		padding: 0px;
	}
	select.mob-box-layout {
		padding: 0.625rem !important;
	}
	.vaccine-font {
		font-size: 20px;
	}
	.textSubTitle {
		font-size: 19px;
	}
}

@media screen and (max-width: 320px) {
	.locationiconstyle {
		padding-right: 5px !important;
		margin-top: 10px;
	}
}

@media screen and (max-width: 768px) {
	.reportstyle {
		line-height: 50px;
		font-size: 36px;
		margin-bottom: 16px;
	}
}
@media screen and (max-width: 1024px) {
	.grclose {
		visibility: visible !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.report-page-boxes {
		gap: 1rem !important;
	}
	.desti-page-layout {
		width: 100% !important;
	}
	.destination-page-boxes {
		grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
		gap: 1rem;
	}
	.activites-page-boxes {
		gap: 1rem;
	}
	.chooseCountry {
		width: 35% !important;
	}
	button.trip-popup-btn {
		width: 30%;
	}
	button.trip-back-btn {
		width: 30%;
	}
	.report-page-boxes {
		gap: 1rem;
	}
	.vaccineDetails {
		margin-top: 114px;
	}
}
