
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.accordion {
  background-color: #eee;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}
.accordion:hover,
.active {
  background-color: #ccc;
}
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
  width: 15px;
}
.rotate {
  transform: rotate(90deg);
}
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
}
.accordion__content::-webkit-scrollbar {
  display: none;
}
.accordion__text {
  padding: 10px;
}
